@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Inter:wght@400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

.font-serif {
  font-family: 'DM Serif Display', serif;
}
